import React, { Component } from 'react';
import './a-propos.css';

export class Apropos extends Component {
    static displayName = Apropos.name;

    render() {
        return (
            <div className="contenu">
                <div className="row">
                    <div className="contenu">
                        <h4 class="display-4 mt-4">À propos du répertoire des formations</h4>

                        <hr class="my-4" />

                        <p><strong>Bienvenue dans le répertoire d’offre de formations destinées aux personnes responsables d’un service de garde éducatif en milieu familial (RSGE) du Québec!</strong></p>

                        <p>Ce répertoire a été produit par une équipe de recherche spécialisée en éducation à la petite enfance de l'UQAM, à la demande du Comité de la formation continue et du perfectionnement des personnes responsables d'un service de garde en milieu familial.</p>

                        <p>Ces formations ont été analysées à l'aide d'une grille permettant de mesurer leur pertinence et leur efficacité pour soutenir le transfert des apprentissages au milieu de garde.</p>

                        <p>La participation à ce projet est faite sur une base volontaire. Ainsi, si une formation ne figure pas au répertoire, cela ne signifie pas qu'elle ne répond pas aux différents critères d'évaluation établis.</p>

                        <p>Les formations indiquées dans le répertoire portent sur les thèmes présents dans le <i>Règlement sur les services de garde éducatifs à l'enfance</i> (art. 57), soit :</p>
                        
                        <ul>
                        <li>Le rôle d'une personne responsable d'un service de garde éducatif en milieu familial;</li>
                        <li>Le développement de l'enfant;</li>
                        <li>La sécurité, la santé et l'alimentation;</li>
                        <li>Le programme éducatif prévu par la Loi.</li>
                        </ul>

                        <p>Enfin, bien qu’il s’adresse principalement aux RSGE, ce répertoire peut également s’avérer fort pertinent pour tous les intervenants du domaine de l’éducation à la petite enfance qui souhaitent se perfectionner pour enrichir leur pratique auprès des enfants d’âge préscolaire.</p>

                        <p>Pour toutes questions de précision concernant les formations offertes au répertoire, veuillez contacter les formateurs directement.</p>

                        <hr class="my-4" />

                        <p>Comment repérer une formation dans le répertoire</p>

                        <p>Le répertoire présente des fonctionnalités de recherche par mots-clés. Il suffit d'inscrire ou sélectionner :</p>
                        <ul>
                        <li>le titre des formations;</li>
                        <li>le titre ou le contenu des fournisseurs de services;</li>
                        <li>les sujets de formation prévus à l’article 57 du <i>Règlement sur les services de garde éducatifs à l’enfance</i> et les thèmes associés;</li>
                        <li>les thèmes;</li>
                        <li>les moyens de formation;</li>
                        <li>les régions.</li>
                        </ul>

                        <p>D’autres fonctionnalités utiles sont aussi disponibles :</p>
                        <ul>
                        <li>des boutons d’impression dans chaque fiche individuelle et dans la page d’affichage des résultats;</li>
                        <li>une icône sur la barre d’outils pour retourner à vos résultats de recherche;</li>
                        <li>un bouton qui permet de réinitialiser votre recherche.</li>
                        </ul>

                        <p>Les pages de résultats permettent de survoler des informations générales sur les formations :</p>
                        <ul>
                        <li>le nombre de fiches correspondant aux critères de recherche;</li>
                        <li>le titre et les catégories de sujet de la formation;</li>
                        <li>le nom du ou des fournisseurs de services qui l’offrent;</li>
                        <li>la durée de la formation;</li>
                        <li>les moyens de formation disponibles (en personne, par correspondance ou en ligne);</li>
                        <li>les régions où la formation est offerte et celles où les inscriptions sont possibles sur demande.</li>
                        </ul>

                        <p>À l’intérieur de chaque fiche individuelle, les informations complémentaires suivantes sont disponibles :</p>
                        <ul>
                        <li>les renseignements pour communiquer avec le fournisseur (coordonnées et site Web);</li>
                        <li>les heures de formation associées à chaque sujet réglementaire;</li>
                        <li>la description et les objectifs de la formation;</li>
                        <li>la clientèle cible.</li>
                        </ul>

                        <p>Le développement de plusieurs formations a été rendu possible grâce à l'initiative et au soutien financier du Comité de la formation continue et du perfectionnement des personnes responsables d’un service de garde en milieu familial (Comité) et du gouvernement du Québec.</p>


                        <div className="card">
                            <div className="card-block">
                                <p>Repérez les logos du Comité pour identifier rapidement des formations de qualité qui satisfont aux exigences de formation prévues à l’article 59 du <i>Règlement sur les services de garde éducatifs à l’enfance</i>.</p>
                                <div className="logo-container">
                                    <img src={require('../../pictures/logo_rsge_rgb_couleurs.png')} alt="RSGE Logo" className="logo" />
                                    <img src={require('../../pictures/logo_rsge_rgb_sceau_couleurs.png')} alt="RSGE Sceau" className="logo" />
                                </div>
                            </div>
                        </div>

                        <hr class="my-4" />

                        <p><strong>Légende des régions administratives :</strong></p>

                        <div class="pl-4">
                            <strong>01 -</strong>	Bas-Saint-Laurent <br />
                            <strong>02 -</strong>	Saguenay-Lac-Saint-Jean <br />
                            <strong>03 -</strong>	Capitale-Nationale <br />
                            <strong>04 -</strong>	Mauricie <br />
                            <strong>05 -</strong>	Estrie <br />
                            <strong>06 -</strong>	Montréal <br />
                            <strong>07 -</strong>	Outaouais <br />
                            <strong>08 -</strong>	Abitibi-Témiscamingue <br />
                            <strong>09 -</strong>	Côte-Nord <br />
                            <strong>10 -</strong>	Nord-du-Québec <br />
                            <strong>11 -</strong>	Gaspésie-Îles-de-la-Madeleine <br />
                            <strong>12 -</strong>	Chaudière-Appalaches <br />
                            <strong>13 -</strong>	Laval <br />
                            <strong>14 -</strong>	Lanaudière <br />
                            <strong>15 -</strong>	Laurentides <br />
                            <strong>16 -</strong>	Montérégie <br />
                            <strong>17 -</strong>	Centre-du-Québec <br />
                            <br />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
